<template>
  <div class="report-content">
    <div class="report-infobox">
      <div class="__header">
        <h4>Member</h4>
      </div>
      <div class="__body">
        <div class="row">
          <div class="col-md-6">
            <div class="subcscription-card">
              <div class="imgbox">
                <img class="nest-logo" src="../assets/nest-logo.png" alt="" />
              </div>
              <div class="_body">
                <p class="date"><span>Purchased on: </span> 20 june 2021</p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="subcscription-card">
              <div class="imgbox">
                <img class="nest-logo" src="../assets/nest-logo.png" alt="" />
              </div>
              <div class="_body">
                <p class="date"><span>Purchased on: </span> 20 june 2021</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-5">
      <div class="report-Notification">
        <div class="__header">
          <h4>
            Notifications
            <span v-if="unreadNotificationCount > 0"
              >{{ unreadNotificationCount }}
            </span>
          </h4>
          <span v-if="unreadNotificationCount > 0">
            <i
              class="fas fa-check-double"
              title="read"
              @click="readAllUnreadNotification()"
            ></i>
          </span>
        </div>
        <div class="__body">
          <ul class="list-unstyled notify-list">
            <div class="empty" v-if="unread_notifications.length == 0">
              All caught up!
            </div>
            <li
              class="read"
              v-for="(notification, notificationIndex) in unread_notifications"
              :key="notificationIndex"
            >
              <p>{{ notification.data.text }}</p>
              <h6>{{ notification.date }}</h6>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationService from "@/services/NotificationService";
import errorLog from "@/errorLog";
export default {
  name: "PupaDashboardTab",
  components: {},
  created() {
    this.getUnreadNotificationCount();
    this.getAllNotifications();
    //this.readNotification();
    this.getAllUnreadNotification();
  },
  data() {
    //   const month = new Date().getMonth();
    // const year = new Date().getFullYear();
    return {
      unreadNotificationCount: 0,
      notificationPage: 1,
      currentNotificationPage: 1,
      totalNotificationPages: 0,
      totalNotifications: 0,
      notifications: [],
      unread_notifications: []
    };
  },
  methods: {
    getUnreadNotificationCount: function() {
      NotificationService.getUnreadNotificationCount()
        .then(result => {
          this.unreadNotificationCount = result.data;
        })
        .catch(error => {
          errorLog.log(error);
        });
    },
    getAllNotifications: function() {
      let params = {
        page: this.currentNotificationPage
      };
      NotificationService.getAllNotifications(params)
        .then(result => {
          this.notifications = result.data.data;
          this.isNotificationsLoading = false;
          this.totalNotificationPages = result.data.last_page;
          this.currentNotificationPage = result.data.current_page;
          this.totalNotifications = result.data.total;
        })
        .catch(error => {
          this.isNotificationsLoading = false;
          errorLog.log(error);
        });
    },
    getAllUnreadNotification: function() {
      let params = {
        page: this.currentNotificationPage
      };
      NotificationService.getAllUnreadNotification(params)
        .then(result => {
          this.unread_notifications = result.data.data;
          this.isNotificationsLoading = false;
          this.totalNotificationPages = result.data.last_page;
          this.currentNotificationPage = result.data.current_page;
          this.totalNotifications = result.data.total;
        })
        .catch(error => {
          this.isNotificationsLoading = false;
          errorLog.log(error);
        });
    },
    readNotification: function(id) {
      const data = { notification_id: id };
      NotificationService.readUnreadNotification(data)
        .then(result => {
          this.readUnreadNotifications = result.data.data;
          this.isNotificationsLoading = false;
          this.totalNotificationPages = result.data.last_page;
          this.currentNotificationPage = result.data.current_page;
          this.totalNotifications = result.data.total;
        })
        .catch(error => {
          this.isNotificationsLoading = false;
          errorLog.log(error);
        });
    },
    readAllUnreadNotification: function() {
      NotificationService.readAllUnreadNotification()
        .then(result => {
          this.readAllUnreadNotifications = result.data.data;
          this.getUnreadNotificationCount();
          this.getAllUnreadNotification();
        })
        .catch(error => {
          errorLog.log(error);
        });
    }
  }
};
</script>

<style lang="scss">
@import "@/style/report-tab.scss";
</style>
