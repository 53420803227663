import Http from "@/services/Http";
export default {
  getUnreadNotificationCount() {
    return Http().post("notification/count");
  },
  getAllNotifications() {
    return Http().post("notification/all");
  },
  readUnreadNotification(data) {
    return Http().post("notification/read/unread", data);
  },
  getAllUnreadNotification() {
    return Http().post("notifications/unread/latest");
  },
  readAllUnreadNotification() {
    return Http().post("notification/read-all/unread");
  }
};
